import React from 'react'
import Data from './Json/Contact.json';
import Banner from "./components/Banner/Index";
import Enquiry from './components/Forms/Enquiry' 
import Container from './components/Container/Index';
import Title from './components/Title/Index'
import { Call, CallMade, CallSharp, Email, EmailSharp, LocationCity, LocationOn, Map, MapsHomeWorkSharp, MapsUgc, MapTwoTone } from '@mui/icons-material';
import contactData from './Json/Contact.json'

const { bannerData } = Data;
const Contact = () => {
  return (
  <>
     {bannerData && <Banner Data={bannerData} />}
    <Container containerClass={'pt-5 mt-5 border-top float-end'} _parentClass={'ContactDetails d-none'}>
      <div className='col-lg-10 col-11 m-auto mt-lg-5'>
      <div className='row'>
      <div className='col-lg-3 col-sm-6 col-12 '>
        <div className='contactDetail'>
        <span className='fs-2 fw-100'>T</span>
          <p><a href="tel:911724374444">+91-172-4374444</a>, <a href="tel:9193160 45838">+91-93160 45838</a></p>
        </div> 
      </div>
      <div className='col-lg-3 col-sm-6 col-12 '>
        <div className='contactDetail'>
          <span className='fs-2 fw-100'>E</span>
          <p><a href="mailto:iec.chandigarh@iecgensets.com">iec.chandigarh@iecgensets.com</a></p>
        </div> 
      </div>
     <div className='col-lg-4 col-sm-6 col-12 '>
        <div className='contactDetail'>
        <span className='fs-2 fw-100'>A</span>
          <p><strong className='text-uppercase'>Corporate Address</strong><br/>Industrial Equipements Company Ltd.<br /> S.C.O. 36, Sector 26, Madhya Marg<br /> Chandigarh - 160019 </p>
        </div> 
        <div className='contactDetail'>
          <p><strong className='text-uppercase'>Factory Address</strong><br/>Plot No. 1-6A, IID Centre<br/>SICOP, Govindsar<br/>Kathua - 184102 (J&K)</p>
        </div> 
      </div>
      <div className='col-lg-2 col-sm-6 col-12'>
        <div className='contactDetail'>
        <span className='fs-2 fw-100'>M</span>
          <p><a href="https://maps.app.goo.gl/WmfiMmrtpVQKASLT7" target="_blank">View Location Map</a></p>
        </div> 
      </div>
      </div>
      </div>
    </Container>
    {contactData.contact && 
    <Container containerClass={'float-end'} _parentClass={'p-100'}>
          <div className='web-container'>
          <Title firstHeading={'IEC Gensets'} secondHeading={'Our Network'} />
        <div className='col-12 float-start'>
            <div className='row'>
             {contactData.contact.map((items, index) => ( 
                 <div className='col-lg-6 col-12' key={index}>
                 <div className="Networkcart h-auto border bg-white p-md-4 p-3">
                  {items.title && 
                     <Title secondHeading={items.title} _secondHeading={'fs-2'} _class={'mb-3 justify-content-start text-start'}/>
                    }
                     <ul>
                       {items.address && <li className='d-flex gap-2'><span><LocationOn /></span><p dangerouslySetInnerHTML={{__html:items.address}}/></li>}
                       {items.phone && 
                       <li className='d-flex gap-2'><span><CallSharp /> </span><p><a href={`tel:${items.phone.replace(/\D/g, '')}`}>{items.phone}</a></p></li>
                       }
                       {items.email &&
                       <li className='d-flex gap-2'><span><Email /> </span><p><a href={`mailto:${items.email}`}>{items.email}</a></p></li>
                      }
                     </ul>
                 </div>
               </div>
             ))}
            </div>
        </div>
          </div>
    </Container>
    }
    {contactData.branches &&
      <Container _parentClass={'branches'} containerClass={'float-end p-100 bg-gray'}>
          <Title firstHeading={'Branches'}/>
            <div className='col-lg-11 col-12 m-auto'>
              <div className='row justify-content-center'>
                {contactData.branches.map((items, index) => (
                  <div className='cardPostion' key={index}>
                    <div className="Networkcart border bg-white p-md-4 p-3">
                  <Title secondHeading={items.title} _secondHeading={'fw-bold fs-4'} _class={'mb-2'}/>
                  <ul>
                    {items.address &&
                    <li className='d-flex gap-2'><span><LocationOn /></span><p className="col-10 float-end" dangerouslySetInnerHTML={{ __html:items.address }}/></li>
                  }
                   {items.phone && <li className='d-flex gap-2'><span><CallSharp /></span><p  className="col-10 float-end" ><a href={`tel:${items.phone.replace(/\D/g, '')}`}>{items.phone}</a></p></li>
                   }
                   {items.email && 
                    <li className='d-flex gap-2'><span><Email /></span><p  className="col-10 float-end"><a href={`mailto:${items.email}`}>{items.email}</a></p></li>
                  }
                  </ul>
                  </div>
              </div>
                ))}
                  
              </div>
            </div>
      </Container>
    }
    <Enquiry /> 
  </>
  )
}

export default Contact