import "./App.css";
import "./fonts/stylesheet.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./Home";
import Header from "./components/Header/Index";
import Footer from "./components/Footer/Index";
import Undermaintaince from "./components/Undermaintaince/Index";
import ScrollToTop from "./ScrollToTop";
import Products from "./Products";
import ProductDetails from "./ProductDetails";
import ProductCat from "./ProductCat";
import Manufacturing from "./Manufacturing";
import Clients from "./Clients";
import Customer from "./Customer";
import RequestQuote from "./RequestQuote";
import Career from "./Career";
import Certifications from "./Certifications";
import Aboutus from "./Aboutus";
import Leadership from "./Leadership";
import Contact from "./Contact";
import ThankYouPage from "./ThankYouPage";
import ContentPage from "./ContentPage";
import Media from "./Media";
import Video from "./components/Media/Video";
import Events from "./components/Media/Events";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <HeaderWithCondition />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-iec-gensets" element={<Aboutus />} />
          <Route path="/power-solutions" element={<ProductCat />} />
          <Route path="/enquiry-submitted" element={<ThankYouPage />} />
          <Route path="/leadership/:slug" element={<Leadership />} />
          <Route
            path="/power-solutions/turnkey-projects"
            element={<ProductDetails />}
          />
          <Route
            path="/power-solutions/compact-substations"
            element={<ProductDetails />}
          />
          <Route path="/power-solutions/:slug" element={<Products />} />
          <Route
            path="/power-solutions/:cat/:slug"
            element={<ProductDetails />}
          />
          <Route path="/manufacturing-plant" element={<Manufacturing />} />
          <Route path="/certifications" element={<Certifications />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/customer-support" element={<Customer />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/request-quote" element={<RequestQuote />} />
          <Route path="/media/:slug" element={<Media />}></Route>
          {/* <Route exact path="/media/events" element={<Events />} />
          <Route exact path="/media/video" element={<Video />} /> */}
          <Route path="/under-construction" element={<Undermaintaince />} />
          <Route path="/:slug" element={<ContentPage />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

function HeaderWithCondition() {
  const location = useLocation();
  // console.log("Current Path:", location.pathname);

  const isConstPage = location.pathname === "/under-construction";
  const isRequestQuote = location.pathname === "/request-quote";
  const isThankPage = location.pathname === "/enquiry-submitted";
  const isLeadershipPage = location.pathname.includes("/leadership");
  const iscontentTerm = location.pathname.includes("/terms-conditions");
  const iscontentPolicy = location.pathname.includes("/privacy-policy");
  const iscontentDis = location.pathname.includes("/legal-disclaimer");
  const isTurnkey = location.pathname.includes("/turnkey-projects");
  const isMedia = location.pathname.includes("/media");
  const isEvent = location.pathname.includes("/media/events");
  const isVideo = location.pathname.includes("/media/video");
  const isPowerSolutionsSlug =
    location.pathname.startsWith("/power-solutions/") &&
    location.pathname.split("/").length === 3;
  const isPowerSolutionsCatSlug =
    location.pathname.startsWith("/power-solutions/") &&
    location.pathname.split("/").length === 4;
  const headerClass =
    isConstPage ||
    isLeadershipPage ||
    isRequestQuote ||
    isThankPage ||
    isTurnkey ||
    isPowerSolutionsSlug ||
    iscontentDis ||
    iscontentPolicy ||
    iscontentTerm ||
    isMedia ||
    isVideo ||
    isEvent ||
    isPowerSolutionsCatSlug
      ? "proHeaderClass position-relative"
      : "";

  return <Header relativeHeader={headerClass} />;
}

export default App;
