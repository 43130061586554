import React, { useEffect, useState } from "react";
import Banner from "./components/Banner/Index";
import { CircularProgress } from "@mui/material";
import Title from "./components/Title/Index";
import FillShape from "./images/Vectors/FillShape";
import Accordions from "./components/Accordions/Index";
import WebContainer from "./components/WebContainer/Index";
import GallerySlider from "./components/Gallery/GallerySlider";
import BusinessEnquiry from "./components/Forms/BusinessEnquiry";
import axios from "axios";
import { API_BASE_URL } from "./Utils/useApi";

const Career = () => {
  const [pageData, setPageData] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/career.php`);
        setPageData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setloading(false);
      }
    };
    fetchData();
  }, []);
  if (loading)
    return (
      <div className="preloader flex-center">
        <CircularProgress size={24} />
      </div>
    );
  if (error) return <div>Error: An error occurred while fetching data</div>;
  if (!pageData) return null;
  const { bannerData, overview, gallery, careerData } = pageData;
  return (
    <main>
      {bannerData && <Banner Data={bannerData} />}
      {overview && (
        <WebContainer
          _parentClass="Overview"
          containerClass={"position-relative "}
        >
          <div className="col-12 float-start position-relative">
            <div className="overviewSign">
              <FillShape />
            </div>
            <Title
              firstHeading={overview.Title}
              secondHeading={overview.subTitle}
              subTitle={overview.descp}
            />
          </div>
        </WebContainer>
      )}
      <WebContainer>
        <Title
          firstHeading={careerData.careerTitle}
          secondHeading={careerData.careerHeading}
        />
        <div className="CurrentOpenings">
          <Accordions Data={careerData.currentOpening} showCareer={true} />
        </div>
      </WebContainer>
      {gallery && (
        <div className="col-12 float-start iecLife px-3">
          <GallerySlider Data={gallery} />
        </div>
      )}
      <BusinessEnquiry />
    </main>
  );
};

export default Career;
