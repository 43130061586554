import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./index.css";
import Download from "../../images/Vectors/Download";
import Work from "../../images/Vectors/Work";
import Map from "../../images/Vectors/Map";

const Accordions = ({ Data, showFilter, showDownloads, showCareer }) => {
  const [expanded, setExpanded] = useState("panel0");
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCheckboxChange = (productName) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.includes(productName)) {
        return prevSelected.filter((product) => product !== productName);
      } else {
        return [...prevSelected, productName];
      }
    });
  };

  return (
    <>
      {Data.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          className="bg-transparent border-0 box-shadow-none accordionDesign removeAfter rowBorder"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            className="titleAccordion px-0"
          >
            <Typography className="fw-600">{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {showFilter && (
                <ul className="filterListing col-12 float-start">
                  {item.Products.map((product, productIndex) => (
                    <li
                      key={productIndex}
                      className="col-12 float-start lh-1 flex-wrap gap-2"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedProducts.includes(
                              product.productName
                            )}
                            onChange={() =>
                              handleCheckboxChange(product.productName)
                            }
                          />
                        }
                        label={product.productName}
                      />
                    </li>
                  ))}
                </ul>
              )}
              {showDownloads && (
                <ul className="col-12 float-start">
                  {item.Products.map((product, productIndex) => (
                    <li
                      key={productIndex}
                      className="col-12 float-start lh-1 flex-wrap gap-2 py-1"
                    >
                      <a
                        className="gap-2 flex-center justify-content-start text-uppercase"
                        href={product.productPath}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Download />
                        {product.productName}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
              {showCareer && (
                <ul className="col-12 float-start">
                  {item.description && (
                    <li>
                      <span>
                        <Work />
                      </span>
                      <p>{item.description}</p>
                    </li>
                  )}
                  {item.location && (
                    <li>
                      <span>
                        <Map />
                      </span>
                      <p>{item.location}</p>
                    </li>
                  )}
                </ul>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default Accordions;
