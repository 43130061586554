import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const FilterAccordion = ({ Data, applyFilters, activeFilters }) => {
  const [expanded, setExpanded] = useState("panel0");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="col-12 float-start bg-primary cardStnComponent card mx-0 gap-0 filterDesign">
      {Data.map((filterCategory, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          className="bg-transparent border-0 box-shadow-none accordionDesign removeAfter rowBorder"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            className="titleAccordion px-0"
          >
            <Typography className="fw-600">{filterCategory.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="filterListing col-12 float-start">
                {filterCategory.options.map((option, idx) => {
                  const isChecked = activeFilters[filterCategory.key].includes(
                    option.name
                  );

                  return (
                    <li
                      key={idx}
                      className="col-12 float-start lh-1 flex-wrap gap-2"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={() =>
                              applyFilters(filterCategory.key, option.name)
                            }
                          />
                        }
                        label={option.name}
                      />
                    </li>
                  );
                })}
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FilterAccordion;
